@tailwind base;
@tailwind components;
@tailwind utilities;

/* .See-Rishab-full-list-coolbutton{
    background-color: black;
    color: white;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 50px;
} */

 @keyframes flashInstagramColors {
    0% {
      background-position: 0% 50%;
    }
    25% {
      background-position: 50% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    75% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  /* Apply gradient and animation to text */
  .flashing-text {
    background: linear-gradient(45deg, #f3a6fb, #ff5f7e, #fdc830, #f3a6fb);
    background-size: 400% 400%;
    color: transparent;
    -webkit-background-clip: text;
    animation: flashInstagramColors 2s ease forwards;
    font-weight: 600;
  }

/* Keyframe animation for subtle background movement */
@keyframes subtleMove {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}

/* Base style for the button */
.See-Rishab-full-list-coolbutton {
    background-color: black;
    margin-top: 20px;
    color: white;
    position: relative;
    padding: 15px 29px; /* Padding to accommodate text and arrow */
    border-radius: 50px;
    cursor: pointer;
    overflow: hidden; /* Ensures the arrow stays within the button */
    font-size: 16px; /* Adjust text size as needed */
    display: inline-flex; /* Ensures text is centered correctly */
    align-items: center; /* Centers text vertically */
    transition: padding-right 0.3s ease; /* Smooth transition for padding */
    white-space: nowrap; /* Prevents text wrapping */
    background-image: url('./images/background.png'); /* Example gradient */
    background-size: 200% 100%; /* Ensure gradient is twice the width of the button */
    animation: moveGradient 10s linear infinite; /* Continuous movement animation */
}

.See-Rishab-full-list-coolbutton-2 {
  background-color: black;
  color: white;
  position: relative;
  cursor: pointer;
  overflow: hidden; /* Ensures the arrow stays within the button */
  background-image: url('./images/background.png'); /* Example gradient */
  background-size: 200% 100%; /* Ensure gradient is twice the width of the button */
}

/* Arrow effect */
.See-Rishab-full-list-coolbutton::after {
    content: '→'; /* Unicode arrow character */
    position: absolute;
    right: 15px; /* Position of the arrow initially */
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
    font-size: 16px; /* Adjust arrow size as needed */
    color: white; /* Adjust arrow color as needed */
}

/* Hover effect */
.See-Rishab-full-list-coolbutton:hover {
    padding-right: 29px; /* Increase padding to make space for the arrow */
    animation: none; /* Stop the movement animation */
    background-position: 100% 0%; /* Ensure background stops at current position */
    transition: background-position 0.5s ease; /* Smooth transition to static background */
}

.See-Rishab-full-list-coolbutton:hover::after {
    opacity: 1; /* Arrow fades in */
}

.See-Rishab-full-list-coolbutton span {
    display: inline-block;
    transition: transform 0.3s ease; /* Smooth transition for text shift */
}

.See-Rishab-full-list-coolbutton:hover span {
    transform: translateX(-4px); /* Move text 2px to the left */
}




/* .See-Rishab-full-list-coolbutton {
    background-color: black;
    margin-top: 20px;
    color: white;
    position: relative;
    padding: 15px 29px; 
    border-radius: 50px;
    cursor: pointer;
    overflow: hidden; 
    font-size: 16px; 
    display: inline-flex; 
    align-items: center; 
    transition: padding-right 0.3s ease;
    white-space: nowrap;
  }

  .See-Rishab-full-list-coolbutton::after {
    content: '→'; 
    position: absolute;
    right: 15px; 
    top: 50%;
    transform: translateY(-50%);
    opacity: 0; 
    transition: opacity 0.3s ease; 
    font-size: 16px; 
    color: white; 
  }
  
  .See-Rishab-full-list-coolbutton:hover {
    padding-right: 29px;
  }
  
  .See-Rishab-full-list-coolbutton:hover::after {
    opacity: 1; 
  }
  
  .See-Rishab-full-list-coolbutton span {
    display: inline-block;
    transition: transform 0.3s ease; 
  }
  
  .See-Rishab-full-list-coolbutton:hover span {
    transform: translateX(-4px); 
  } */
